.top-contact-fab-group {
  position: fixed;
  z-index: 999;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.top-contact-fab-group[data-visible="false"] {
  display: none;
}
.top-contact-fab-group {
  bottom: var(--size-16);
  right: var(--size-16);
}

.top-contact-fab-group-main-fabs {
  display: flex;
  flex-direction: column;
  gap: var(--size-16);
}

.go-top-fab,
.contact-fab,
.whatsapp-fab,
.email-fab {
  width: var(--size-56);
  height: var(--size-56);
}
.close-fab {
  width: var(--size-40);
  height: var(--size-40);
  align-self: center;
  padding: 8px;
}
.go-top-fab,
.whatsapp-fab,
.email-fab,
.close-fab {
  background-color: var(--clr-gray-300);
}

.contact-fab {
  background-color: var(--clr-orange);
}
.contact-fab[data-active="true"] {
  border: 1px solid var(--clr-white-100);
}

.whatsapp-fab .fab-icon,
.email-fab .fab-icon {
  min-width: 32px;
  min-height: 32px;
}

.top-contact-fab-group-option-fabs {
  position: fixed;
  z-index: 999;
  display: flex;
  gap: var(--size-8);
  right: var(--size-80);
  bottom: var(--size-16);
}
.top-contact-fab-group-option-fabs[data-visible="false"] {
  display: none;
}
