.prev-next-page-section {
  display: block;
  position: relative;
  background-color: var(--clr-primary-700);
  height: var(--prev-next-page-section-height);
}

.prev-page-button {
  position: absolute;
  left: 12%;
  text-align: end;
}

.next-page-button {
  position: absolute;
  left: 52%;
}

.prev-page-secondary-text,
.next-page-secondary-text {
  opacity: 0.7;
}

.prev-page-primary-text {
  transition: margin-right 0.1s ease;
}
.prev-page-button:hover > .prev-page-primary-text {
  margin-right: 4px;
}

.next-page-primary-text {
  transition: margin-left 0.1s ease;
}
.next-page-button:hover > .next-page-primary-text {
  margin-left: 4px;
}
