.nav-card {
  display: block;
  position: relative;
  min-width: 16.75rem;
  height: var(--nav-card-height);
}
@media (max-width: 900px) {
  .nav-card {
    height: var(--nav-card-min-height);
  }
}
.nav-card-bg-img {
  position: absolute;
  width: 100%;
  height: 100%;
}

.nav-card-title {
  position: absolute;
  bottom: 0;
  width: 100%;
  color: var(--clr-white-87);
  background-color: var(--clr-gray-60);
  text-align: start;
}

.nav-card-overlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0;
}
.nav-card:hover > .nav-card-overlay {
  opacity: 8%;
}
.nav-card:active > .nav-card-overlay {
  opacity: 16%;
}
