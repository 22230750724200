.blog-content-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: var(--size-24);
}
@media (max-width: 900px) {
  .blog-content-wrapper {
    grid-template-columns: 1fr 1fr;
    gap: var(--size-20);
  }
}
@media (max-width: 600px) {
  .blog-content-wrapper {
    grid-template-columns: auto;
    gap: var(--size-16);
  }
}

.blog-card-image {
  height: 454px;
}
@media (max-width: 900px) {
  .blog-card-image {
    height: 360px;
  }
}
@media (max-width: 600px) {
  .blog-card-image {
    height: 280px;
  }
}
