.dropdown-button {
  position: relative;
  background-color: var(--clr-primary-900);
  min-width: 216px;
  max-width: 274px;
  border-radius: 8px;
  color: var(--clr-white-87);
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
.dropdown-button[data-expanded="true"] {
  border-radius: 8px 8px 0 0;
}
.dropdown-button-upper-part {
  display: grid;
  grid-template-columns: 11fr 1fr;
  align-content: center;
  min-height: 55px;
  border-radius: 8px;
  gap: var(--size-12);
  cursor: pointer;
}

.dropdown-button-texts-container {
  grid-row: 1 / 3;
  grid-column: 1 / 1;
}

.dropdown-button-arrow-container {
  display: flex;
  grid-row: 1 / 3;
  grid-column: 2 / 2;
  justify-content: flex-start;
  align-items: center;
}

.dropdown-button-arrow {
  min-height: 26px;
  min-width: 26px;
  margin-block: auto;
  transition: padding-top 0.1s ease;
}
@media (max-width: 600px) {
  .dropdown-button-arrow {
    height: 4vw;
    width: 4vw;
  }
}
.dropdown-button:hover > .dropdown-button-arrow {
  padding-top: 2px;
}

.dropdown-anchor {
  position: relative;
}
.dropdown-button-dropdown[data-expanded="false"] {
  display: none;
}
.dropdown-button-dropdown {
  position: absolute;
  z-index: 999;
  left: 0;
  right: 0;
  background-color: var(--clr-primary-900);
  border-radius: 0 0 8px 8px;
}

.dropdown-button-dropdown-item {
  display: flex;
  gap: var(--size-8);
  cursor: pointer;
}

.dropdown-button-dropdown-item:first-child {
  padding-top: var(--size-12);
  border-top: 2px solid rgba(255, 255, 255, 0.1);
}
