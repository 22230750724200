.text-color-black-87 {
  color: var(--clr-black-87);
}
.text-color-primary-900 {
  color: var(--clr-primary-900);
}
.text-color-primary-800 {
  color: var(--clr-primary-800);
}
.text-color-white-100 {
  color: var(--clr-white-100);
}
.text-color-white-87 {
  color: var(--clr-white-87);
}
.text-color-white-60 {
  color: var(--clr-white-60);
}
.text-color-white-38 {
  color: var(--clr-white-38);
}
.text-color-primary-800 {
  color: var(--clr-primary-800);
}
.text-color-primary-600 {
  color: var(--clr-primary-600);
}
.text-color-primary-500 {
  color: var(--clr-primary-500);
}
.text-color-primary-400 {
  color: var(--clr-primary-400);
}

.background-color-gray-87 {
  background-color: var(--clr-gray-87);
}

.background-color-gray-60 {
  background-color: var(--clr-gray-60);
}

.background-color-gray-38 {
  background-color: var(--clr-gray-38);
}

.background-color-gray-900 {
  background-color: var(--clr-gray-900);
}
.background-color-gray-800 {
  background-color: var(--clr-gray-800);
}
.background-color-gray-700 {
  background-color: var(--clr-gray-700);
}
.background-color-gray-600 {
  background-color: var(--clr-gray-600);
}
.background-color-gray-500 {
  background-color: var(--clr-gray-500);
}
.background-color-gray-400 {
  background-color: var(--clr-gray-400);
}
.background-color-gray-300 {
  background-color: var(--clr-gray-300);
}
.background-color-gray-200 {
  background-color: var(--clr-gray-200);
}
.background-color-gray-100 {
  background-color: var(--clr-gray-100);
}
.background-color-primary-1000 {
  background-color: var(--clr-primary-1000);
}
.background-color-primary-900 {
  background-color: var(--clr-primary-900);
}
.background-color-primary-850 {
  background-color: var(--clr-primary-850);
}
.background-color-primary-800 {
  background-color: var(--clr-primary-800);
}
.background-color-primary-650 {
  background-color: var(--clr-primary-650);
}
.background-color-primary-400 {
  background-color: var(--clr-primary-400);
}
.background-color-black-87 {
  background-color: var(--clr-black-87);
}
.background-color-black-60 {
  background-color: var(--clr-black-60);
}
.background-color-white-87 {
  background-color: var(--clr-white-87);
}
.background-color-white-60 {
  background-color: var(--clr-white-60);
}
.background-color-white-38 {
  background-color: var(--clr-white-38);
}

.vertical-align-center {
  align-items: center;
}

.justify-self-end {
  justify-self: end;
}

.h1-font {
  font-family: "sf-pro-display-light";
  font-size: 96px;
}

.h2-font {
  font-family: "sf-pro-display-light";
  font-size: 60px;
}

.h3-font {
  font-family: "sf-pro-display-regular";
  font-size: 48px;
}

.h4-font {
  font-family: "sf-pro-display-regular";
  font-size: 34px;
}

.h5-font {
  font-family: "sf-pro-display-medium";
  font-size: 24px;
}

.h6-font {
  font-family: "sf-pro-display-medium";
  font-size: 20px;
}

.b1-font {
  font-family: "sf-pro-display-regular";
  font-size: 20px;
}

.b2-font {
  font-family: "sf-pro-display-regular";
  font-size: 16px;
}

.button-font {
  font-family: "sf-pro-display-medium";
  font-size: 14px;
}

.caption-font {
  font-family: "sf-pro-display-regular";
  font-size: 12px;
}

.overline-font {
  font-family: "sf-pro-display-medium";
  font-size: 10px;
}

@media (max-width: 900px) {
  .h2-font-md-only {
    font-family: "sf-pro-display-light";
    font-size: 60px;
  }
}
@media (max-width: 900px) {
  .h3-font-md-only {
    font-family: "sf-pro-display-regular";
    font-size: 48px;
  }
}
@media (max-width: 900px) {
  .h4-font-md-only {
    font-family: "sf-pro-display-regular";
    font-size: 34px;
  }
}
@media (max-width: 900px) {
  .h4-font-italic-md-only {
    font-family: "sf-pro-display-regular";
    font-size: 34px;
  }
}
@media (max-width: 900px) {
  .h5-font-md-only {
    font-family: "sf-pro-display-medium";
    font-size: 24px;
  }
}
@media (max-width: 900px) {
  .h6-font-md-only {
    font-family: "sf-pro-display-medium";
    font-size: 20px;
  }
}
@media (max-width: 900px) {
  .b1-font-md-only {
    font-family: "sf-pro-display-regular";
    font-size: 20px;
  }
}
@media (max-width: 900px) {
  .b2-font-md-only {
    font-family: "sf-pro-display-regular";
    font-size: 16px;
  }
}
@media (max-width: 900px) {
  .button-font-md-only {
    font-family: "sf-pro-display-medium";
    font-size: 14px;
  }
}
@media (max-width: 600px) {
  .h3-font-sm-only {
    font-family: "sf-pro-display-regular";
    font-size: 48px;
  }
}
@media (max-width: 600px) {
  .h4-font-sm-only {
    font-family: "sf-pro-display-regular";
    font-size: 34px;
  }
}
@media (max-width: 600px) {
  .h4-font-italic-sm-only {
    font-family: "sf-pro-display-regular";
    font-size: 34px;
  }
}
@media (max-width: 600px) {
  .h5-font-sm-only {
    font-family: "sf-pro-display-medium";
    font-size: 24px;
  }
}
@media (max-width: 600px) {
  .h6-font-sm-only {
    font-family: "sf-pro-display-medium";
    font-size: 20px;
  }
}
@media (max-width: 600px) {
  .b1-font-sm-only {
    font-family: "sf-pro-display-regular";
    font-size: 20px;
  }
}
@media (max-width: 600px) {
  .b2-font-sm-only {
    font-family: "sf-pro-display-regular";
    font-size: 16px;
  }
}
@media (max-width: 600px) {
  .button-font-sm-only {
    font-family: "sf-pro-display-medium";
    font-size: 14px;
  }
}
@media (max-width: 400px) {
  .h6-font-under-400-only {
    font-family: "sf-pro-display-medium";
    font-size: 20px;
  }
}
@media (max-width: 400px) {
  .b2-font-under-400-only {
    font-family: "sf-pro-display-regular";
    font-size: 16px;
  }
}
@media (max-width: 400px) {
  .button-font-under-400-only {
    font-family: "sf-pro-display-medium";
    font-size: 14px;
  }
}

.transparent-font {
  color: var(--clr-white-48);
}
.p-indent {
  text-indent: 5rem;
}

.margin-8 {
  margin: var(--size-8);
}
.margin-top-3 {
  margin-block-start: var(--size-3);
}
.margin-top-4 {
  margin-block-start: var(--size-4);
}
.margin-top-6 {
  margin-block-start: var(--size-6);
}
.margin-top-8 {
  margin-block-start: var(--size-8);
}
.margin-top-12 {
  margin-block-start: var(--size-12);
}
.margin-top-16 {
  margin-block-start: var(--size-16);
}
.margin-top-20 {
  margin-block-start: var(--size-20);
}
.margin-top-24 {
  margin-block-start: var(--size-24);
}
.margin-top-28 {
  margin-block-start: var(--size-28);
}
.margin-top-32 {
  margin-block-start: var(--size-32);
}
.margin-top-40 {
  margin-block-start: var(--size-40);
}
.margin-top-48 {
  margin-block-start: var(--size-48);
}
.margin-top-56 {
  margin-block-start: var(--size-56);
}
.margin-top-64 {
  margin-block-start: var(--size-64);
}
.margin-top-72 {
  margin-block-start: var(--size-72);
}
.margin-top-80 {
  margin-block-start: var(--size-80);
}
.margin-top-88 {
  margin-block-start: var(--size-88);
}
.margin-top-96 {
  margin-block-start: var(--size-96);
}
.margin-top-120 {
  margin-block-start: var(--size-120);
}
.margin-top-160 {
  margin-block-start: var(--size-160);
}
@media (max-width: 900px) {
  .margin-top-160-md-only {
    margin-block-start: var(--size-160);
  }
}
@media (max-width: 900px) {
  .margin-top-120-md-only {
    margin-block-start: var(--size-120);
  }
}
@media (max-width: 900px) {
  .margin-top-64-md-only {
    margin-block-start: var(--size-64);
  }
}
@media (max-width: 900px) {
  .margin-top-48-md-only {
    margin-block-start: var(--size-48);
  }
}
@media (max-width: 900px) {
  .margin-top-40-md-only {
    margin-block-start: var(--size-40);
  }
}
@media (max-width: 900px) {
  .margin-top-32-md-only {
    margin-block-start: var(--size-32);
  }
}
@media (max-width: 900px) {
  .margin-top-24-md-only {
    margin-block-start: var(--size-24);
  }
}
@media (max-width: 900px) {
  .margin-top-20-md-only {
    margin-block-start: var(--size-20);
  }
}
@media (max-width: 900px) {
  .margin-top-16-md-only {
    margin-block-start: var(--size-16);
  }
}
@media (max-width: 900px) {
  .margin-top-0-md-only {
    margin-block-start: 0;
  }
}

@media (max-width: 600px) {
  .margin-top-64-sm-only {
    margin-block-start: var(--size-64);
  }
}
@media (max-width: 600px) {
  .margin-top-32-sm-only {
    margin-block-start: var(--size-32);
  }
}
@media (max-width: 600px) {
  .margin-top-24-sm-only {
    margin-block-start: var(--size-24);
  }
}
@media (max-width: 600px) {
  .margin-top-16-sm-only {
    margin-block-start: var(--size-16);
  }
}
@media (max-width: 600px) {
  .margin-top-12-sm-only {
    margin-block-start: var(--size-12);
  }
}
.margin-bottom-64 {
  margin-block-end: var(--size-64);
}
.margin-bottom-48 {
  margin-block-end: var(--size-48);
}
.margin-bottom-32 {
  margin-block-end: var(--size-32);
}
.margin-bottom-6 {
  margin-block-end: var(--size-6);
}

.mx-24 {
  margin-inline: var(--size-24);
}
.mx-16 {
  margin-inline: var(--size-16);
}
.mx-12 {
  margin-inline: var(--size-12);
}
.mx-8 {
  margin-inline: var(--size-8);
}
.mx-auto {
  margin-inline: auto;
}
@media (max-width: 600px) {
  .mx-6-sm-only {
    margin-inline: var(--size-6);
  }
}
@media (max-width: 600px) {
  .mx-4-sm-only {
    margin-inline: var(--size-4);
  }
}

.padding-24 {
  padding: var(--size-24);
}
.padding-20 {
  padding: var(--size-20);
}
.padding-16 {
  padding: var(--size-16);
}
.padding-8 {
  padding: var(--size-8);
}

.padding-block-3 {
  padding-block: var(--size-3);
}
.padding-block-4 {
  padding-block: var(--size-4);
}
.padding-block-6 {
  padding-block: var(--size-6);
}
.padding-block-8 {
  padding-block: var(--size-8);
}
.padding-block-12 {
  padding-block: var(--size-12);
}
.padding-block-16 {
  padding-block: var(--size-16);
}
.padding-block-20 {
  padding-block: var(--size-20);
}
.padding-block-24 {
  padding-block: var(--size-24);
}
.padding-block-28 {
  padding-block: var(--size-28);
}
.padding-block-32 {
  padding-block: var(--size-32);
}
.padding-block-40 {
  padding-block: var(--size-40);
}
.padding-block-48 {
  padding-block: var(--size-48);
}
.padding-block-64 {
  padding-block: var(--size-64);
}
.padding-block-72 {
  padding-block: var(--size-72);
}
.padding-block-80 {
  padding-block: var(--size-80);
}
.padding-block-88 {
  padding-block: var(--size-88);
}
.padding-block-96 {
  padding-block: var(--size-96);
}
.padding-block-120 {
  padding-block: var(--size-120);
}

@media (max-width: 900px) {
  .padding-block-96-md-only {
    padding-block: var(--size-96);
  }
}
@media (max-width: 900px) {
  .padding-block-48-md-only {
    padding-block: var(--size-48);
  }
}
@media (max-width: 900px) {
  .padding-block-40-md-only {
    padding-block: var(--size-40);
  }
}
@media (max-width: 900px) {
  .padding-block-32-md-only {
    padding-block: var(--size-32);
  }
}
@media (max-width: 900px) {
  .padding-block-24-md-only {
    padding-block: var(--size-24);
  }
}

@media (max-width: 600px) {
  .padding-block-72-sm-only {
    padding-block: var(--size-72);
  }
}
@media (max-width: 600px) {
  .padding-block-16-sm-only {
    padding-block: var(--size-16);
  }
}
@media (max-width: 600px) {
  .padding-block-24-sm-only {
    padding-block: var(--size-24);
  }
}
@media (max-width: 600px) {
  .padding-block-32-sm-only {
    padding-block: var(--size-32);
  }
}
@media (max-width: 400px) {
  .padding-block-16-under-400-only {
    padding-block: var(--size-16);
  }
}
@media (max-width: 400px) {
  .padding-block-24-under-400-only {
    padding-block: var(--size-24);
  }
}

.padding-top-3 {
  padding-block-start: var(--size-3);
}
.padding-top-4 {
  padding-block-start: var(--size-4);
}
.padding-top-6 {
  padding-block-start: var(--size-6);
}
.padding-top-8 {
  padding-block-start: var(--size-8);
}
.padding-top-12 {
  padding-block-start: var(--size-12);
}
.padding-top-16 {
  padding-block-start: var(--size-16);
}
.padding-top-20 {
  padding-block-start: var(--size-20);
}
.padding-top-24 {
  padding-block-start: var(--size-24);
}
.padding-top-28 {
  padding-block-start: var(--size-28);
}
.padding-top-32 {
  padding-block-start: var(--size-32);
}
.padding-top-40 {
  padding-block-start: var(--size-40);
}
.padding-top-48 {
  padding-block-start: var(--size-48);
}
.padding-top-56 {
  padding-block-start: var(--size-56);
}
.padding-top-64 {
  padding-block-start: var(--size-64);
}
.padding-top-72 {
  padding-block-start: var(--size-72);
}
.padding-top-80 {
  padding-block-start: var(--size-80);
}
.padding-top-88 {
  padding-block-start: var(--size-88);
}
.padding-top-96 {
  padding-block-start: var(--size-96);
}
.padding-top-120 {
  padding-block-start: var(--size-120);
}
.padding-top-160 {
  padding-block-start: var(--size-160);
}
.padding-top-180 {
  padding-block-start: var(--size-180);
}
.padding-top-240 {
  padding-block-start: var(--size-240);
}

@media (max-width: 900px) {
  .padding-top-28-md-only {
    padding-block-start: var(--size-28);
  }
}
@media (max-width: 900px) {
  .padding-top-32-md-only {
    padding-block-start: var(--size-32);
  }
}
@media (max-width: 900px) {
  .padding-top-48-md-only {
    padding-block-start: var(--size-48);
  }
}
@media (max-width: 900px) {
  .padding-top-56-md-only {
    padding-block-start: var(--size-56);
  }
}
@media (max-width: 900px) {
  .padding-top-64-md-only {
    padding-block-start: var(--size-64);
  }
}
@media (max-width: 600px) {
  .padding-top-48-sm-only {
    padding-block-start: var(--size-48);
  }
}
@media (max-width: 600px) {
  .padding-top-40-sm-only {
    padding-block-start: var(--size-40);
  }
}
@media (max-width: 600px) {
  .padding-top-32-sm-only {
    padding-block-start: var(--size-32);
  }
}
@media (max-width: 600px) {
  .padding-top-24-sm-only {
    padding-block-start: var(--size-24);
  }
}
@media (max-width: 600px) {
  .padding-top-20-sm-only {
    padding-block-start: var(--size-20);
  }
}
@media (max-width: 600px) {
  .padding-top-16-sm-only {
    padding-block-start: var(--size-16);
  }
}

@media (max-width: 400px) {
  .padding-top-16-under-400-only {
    padding-block-start: var(--size-16);
  }
}
@media (max-width: 400px) {
  .padding-top-24-under-400-only {
    padding-block-start: var(--size-24);
  }
}

.padding-bottom-3 {
  padding-block-end: var(--size-3);
}
.padding-bottom-4 {
  padding-block-end: var(--size-4);
}
.padding-bottom-6 {
  padding-block-end: var(--size-6);
}
.padding-bottom-8 {
  padding-block-end: var(--size-8);
}
.padding-bottom-12 {
  padding-block-end: var(--size-12);
}
.padding-bottom-16 {
  padding-block-end: var(--size-16);
}
.padding-bottom-20 {
  padding-block-end: var(--size-20);
}
.padding-bottom-24 {
  padding-block-end: var(--size-24);
}
.padding-bottom-28 {
  padding-block-end: var(--size-28);
}
.padding-bottom-32 {
  padding-block-end: var(--size-32);
}
.padding-bottom-40 {
  padding-block-end: var(--size-40);
}
.padding-bottom-48 {
  padding-block-end: var(--size-48);
}
.padding-bottom-64 {
  padding-block-end: var(--size-64);
}
.padding-bottom-72 {
  padding-block-end: var(--size-72);
}
.padding-bottom-80 {
  padding-block-end: var(--size-80);
}
.padding-bottom-88 {
  padding-block-end: var(--size-88);
}
.padding-bottom-96 {
  padding-block-end: var(--size-96);
}
.padding-bottom-104 {
  padding-block-end: var(--size-104);
}
.padding-bottom-120 {
  padding-block-end: var(--size-120);
}
.padding-bottom-184 {
  padding-block-end: var(--size-184);
}

@media (max-width: 900px) {
  .padding-bottom-32-md-only {
    padding-block-end: var(--size-32);
  }
}
@media (max-width: 900px) {
  .padding-bottom-40-md-only {
    padding-block-end: var(--size-40);
  }
}
@media (max-width: 900px) {
  .padding-bottom-48-md-only {
    padding-block-end: var(--size-48);
  }
}
@media (max-width: 900px) {
  .padding-bottom-64-md-only {
    padding-block-end: var(--size-64);
  }
}
@media (max-width: 900px) {
  .padding-bottom-80-md-only {
    padding-block-end: var(--size-80);
  }
}
@media (max-width: 900px) {
  .padding-bottom-96-md-only {
    padding-block-end: var(--size-96);
  }
}
@media (max-width: 900px) {
  .padding-bottom-120-md-only {
    padding-block-end: var(--size-120);
  }
}

@media (max-width: 600px) {
  .padding-bottom-32-sm-only {
    padding-block-end: var(--size-32);
  }
}
@media (max-width: 600px) {
  .padding-bottom-48-sm-only {
    padding-block-end: var(--size-48);
  }
}
@media (max-width: 600px) {
  .padding-bottom-64-sm-only {
    padding-block-end: var(--size-64);
  }
}
@media (max-width: 600px) {
  .padding-bottom-96-sm-only {
    padding-block-end: var(--size-96);
  }
}

.padding-left-16 {
  padding-left: var(--size-16);
}
.padding-left-24 {
  padding-left: var(--size-24);
}
.padding-left-48 {
  padding-left: var(--size-48);
}
.padding-right-24 {
  padding-right: var(--size-24);
}
.padding-right-16 {
  padding-right: var(--size-16);
}
.padding-inline-8 {
  padding-inline: var(--size-8);
}
.padding-inline-12 {
  padding-inline: var(--size-12);
}
.padding-inline-16 {
  padding-inline: var(--size-16);
}
.padding-inline-20 {
  padding-inline: var(--size-20);
}
.padding-inline-24 {
  padding-inline: var(--size-24);
}
.padding-inline-32 {
  padding-inline: var(--size-32);
}
.padding-inline-40 {
  padding-inline: var(--size-40);
}
.padding-inline-48 {
  padding-inline: var(--size-48);
}
.padding-inline-64 {
  padding-inline: var(--size-64);
}
.padding-inline-96 {
  padding-inline: var(--size-96);
}
.padding-inline-120 {
  padding-inline: var(--size-120);
}
@media (max-width: 900px) {
  .padding-inline-16-md-only {
    padding-inline: var(--size-16);
  }
}
@media (max-width: 900px) {
  .padding-inline-24-md-only {
    padding-inline: var(--size-24);
  }
}
@media (max-width: 900px) {
  .padding-inline-32-md-only {
    padding-inline: var(--size-32);
  }
}
@media (max-width: 900px) {
  .padding-inline-40-md-only {
    padding-inline: var(--size-40);
  }
}

@media (max-width: 600px) {
  .padding-inline-0-sm-only {
    padding-inline: 0;
  }
}
@media (max-width: 600px) {
  .padding-inline-8-sm-only {
    padding-inline: var(--size-8);
  }
}
@media (max-width: 600px) {
  .padding-inline-16-sm-only {
    padding-inline: var(--size-16);
  }
}
@media (max-width: 600px) {
  .padding-inline-20-sm-only {
    padding-inline: var(--size-20);
  }
}
@media (max-width: 600px) {
  .padding-inline-24-sm-only {
    padding-inline: var(--size-24);
  }
}
@media (max-width: 400px) {
  .padding-inline-0-under-400-only {
    padding-inline: 0;
  }
}

@media (max-width: 400px) {
  .padding-inline-16-under-400-only {
    padding-inline: var(--size-16);
  }
}
@media (max-width: 400px) {
  .padding-inline-24-under-400-only {
    padding-inline: var(--size-24);
  }
}
@media (max-width: 400px) {
  .padding-inline-32-under-400-only {
    padding-inline: var(--size-32);
  }
}
.padding-top-after-appbar {
  padding-top: var(--app-bar-height);
}

.text-decoration-none {
  text-decoration: none;
}
.text-uppercase {
  text-transform: uppercase;
}
.box-shadow-sm {
  box-shadow: var(--box-shadow-sm);
}
.box-shadow-md {
  box-shadow: var(--box-shadow-md);
}
.box-shadow-sm {
  box-shadow: var(--box-shadow-sm);
}

.container {
  width: var(--container-width);
  margin-inline: auto;
}
.container-narrow {
  width: var(--container-width);
  margin-inline: auto;
  padding-right: var(--container-narrow-padding-right);
}
@media (max-width: 1110px) {
  .container-narrow-sm {
    width: var(--container-width);
    margin-inline: auto;
    padding-right: calc(var(--container-narrow-padding-right) / 1.3);
  }
}
@media (max-width: 900px) {
  .container-narrow-sm {
    width: var(--container-width);
    margin-inline: auto;
    padding-right: 0;
  }
}

.full-width {
  width: 100%;
}

:where(.flow :not(:first-child)) {
  margin-top: var(--flow-spacer, 3em);
}

.white-space-pre-line {
  white-space: pre-wrap;
}
