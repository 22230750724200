/* variables */
:root {
  --clr-primary-400: #80d9e0;
  --clr-primary-500: #00a3b0;
  --clr-primary-600: #008f98;
  --clr-primary-650: #005b5b;
  --clr-primary-700: #124545;
  --clr-primary-800: #1b5050;
  --clr-primary-850: #233a3a;
  --clr-primary-900: #003737;
  --clr-primary-1000: #041f1f;

  --clr-black-38: rgba(0, 0, 0, 0.38);
  --clr-black-60: rgba(0, 0, 0, 0.6);
  --clr-black-87: rgba(0, 0, 0, 0.87);

  --clr-gray-38: rgba(40, 49, 49, 0.38);
  --clr-gray-60: rgba(40, 49, 49, 0.6);
  --clr-gray-87: rgba(40, 49, 49, 0.87);

  --clr-gray-100: #797d7e;
  --clr-gray-200: #646a6b;
  --clr-gray-300: #505556;
  --clr-gray-400: #414748;
  --clr-gray-500: #3a4142;
  --clr-gray-600: #383e3f;
  --clr-gray-700: #383838;
  --clr-gray-800: #272e2f;
  --clr-gray-900: #17191a;

  --clr-white-100: rgba(255, 255, 255);
  --clr-white-87: rgba(255, 255, 255, 0.87);
  --clr-white-60: rgba(255, 255, 255, 0.6);
  --clr-white-48: rgba(255, 255, 255, 0.48);
  --clr-white-38: rgba(255, 255, 255, 0.38);
  --clr-white-24: rgba(255, 255, 255, 0.24);

  --clr-orange: #e98523;
  --clr-error: #ff7043;

  --ff-body: "sf-pro-display-regular";
  --fs-body: 16px;

  --fs-body: 16px;

  --fw-light: 300;
  --fw-regular: 400;
  --fw-medium: 500;

  --size--8: -0.5rem; /* -8px */
  --size--6: -0.375rem; /* -6px */
  --size--4: -0.25rem; /* -4px */
  --size-0: 0; /* 0px */
  --size-3: 0.188rem; /* 3px */
  --size-4: 0.25rem; /* 4px */
  --size-6: 0.375rem; /* 6px */
  --size-8: 0.5rem; /* 8px */
  --size-10: 0.625rem; /* 10px */
  --size-12: 0.75rem; /* 12px */
  --size-16: 1rem; /* 16px */
  --size-20: 1.25rem; /* 20px */
  --size-24: 1.5rem; /* 24px */
  --size-28: 1.75rem; /* 28px */
  --size-32: 2rem; /* 32px */
  --size-40: 2.5rem; /* 40px */
  --size-48: 3rem; /* 48px */
  --size-52: 3.25rem; /* 52px */
  --size-56: 3.5rem; /* 56px */
  --size-64: 4rem; /* 64px */
  --size-72: 4.5rem; /* 72px */
  --size-80: 5rem; /* 80px */
  --size-88: 5.5rem; /* 88px */
  --size-96: 6rem; /* 96px */
  --size-104: 6.5rem; /* 104px */
  --size-120: 7.5rem; /* 120px */
  --size-144: 9rem; /* 144px */
  --size-160: 10rem; /* 160px */
  --size-180: 11.25rem; /* 180px */
  --size-200: 12.5rem; /* 200px */
  --size-240: 15rem; /* 240px */
  --size-320: 20rem; /* 320px */

  --container-max-width: 1440px;
  --container-padding: 1rem;
  --container-width: min(
    var(--container-max-width),
    100% - (var(--container-padding) * 2)
  );

  --container-narrow-padding-right: 24rem;

  --app-bar-height: var(--size-88);
  /* --app-bar-height-medium: 126px; */
  /* --app-bar-height-long: var(--size-144); */

  --nav-card-height: 196px;
  --nav-card-min-height: 172px;
  --card-button-min-width: 18rem;

  --design-playground-options-width: 298px;

  --gallery-image-height: 416px;
  --gallery-image-height-sm: 312px;

  --footer-height: 240px;
  --prev-next-page-section-height: 156px;

  --box-shadow-sm: 1px 1px 2px 0.5px var(--clr-black-87);
  --box-shadow-md: 1px 1px 2px 0.5px var(--clr-black-60);
  --box-shadow-sm: 1px 1px 2px 0.5px var(--clr-black-38);

  --page-title-height: 312px;
  --model-hero-height: 100vh;

  --section-height-lg: 100vh;
  --section-height-md: 80vh;
  --section-height-sm: 75vh;
  --section-max-height: 1180px;

  --hero-section-image-url: "";
  --page-title-image-url: "";
  --model-hero-section-image-url: "";
  --testimonial-section-image-url: "";
}

@media (max-width: 1080px) {
  :root {
    --app-bar-height: var(--size-144);
  }
}
@media (max-width: 700px) {
  :root {
    --app-bar-height: var(--size-88);
  }
}
