.gallery-section-image {
  object-fit: contain;
}
.gallery-section-carousel-image {
  height: 70vh;
  background-color: var(--clr-black-38);
}
@media (max-width: 480px) {
  .gallery-section-carousel-image {
    height: 30vh;
  }
}
@media (max-width: 360px) {
  .gallery-section-carousel-image {
    height: 30vh;
  }
}

.gallery-section-carousel-image[data-fullscreen="true"] {
  height: 75vh;
}

.gallery-section-next-images {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  justify-content: center;
  gap: var(--size-24);
}
@media (max-width: 1080px) {
  .gallery-section-next-images {
    gap: var(--size-12);
  }
}

.gallery-section-next-image {
  width: 100%;
  height: 15vh;
  max-height: 196px;
  object-fit: cover;
  transition: transform 0.1s ease;
}
@media (max-width: 1080px) {
  .gallery-section-next-image {
    max-height: var(--size-144);
  }
}
@media (max-width: 700px) {
  .gallery-section-next-image {
    max-height: var(--size-120);
  }
}
@media (max-width: 600px) {
  .gallery-section-next-image {
    max-height: var(--size-96);
  }
}
@media (max-width: 400px) {
  .gallery-section-next-image {
    max-height: var(--size-64);
  }
}
.gallery-section-next-image:hover {
  cursor: pointer;
  transform: scale(1.01);
}

.slick-slide > div {
  margin: 0 12px;
}
.slick-list {
  margin: 0 -12px;
}
.slick-prev {
  left: -68px;
  top: 40%;
}
.slick-next {
  right: -40px;
  top: 40%;
}
.slick-prev:before,
.slick-next:before {
  opacity: 0.6;
}

.gallery-section-carousel > .slick-prev:before {
  content: url("/public/icons/arrow-circle-left-white.svg");
}
.gallery-section-carousel > .slick-next:before {
  content: url("/public/icons/arrow-circle-right-white.svg");
}
