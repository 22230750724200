.gallery-dropdowns {
  display: flex;
  gap: var(--size-16);
}
@media (max-width: 480px) {
  .gallery-dropdowns {
    flex-direction: column;
  }
}

.gallery-images {
  display: flex;
  gap: var(--size-16);
  flex-wrap: wrap;
}
.gallery-image {
  object-fit: cover;
  height: 231px;
  width: 336px;
  flex-grow: 1;
}

.gallery-carousel-container[data-open="false"] {
  display: none;
}
.gallery-carousel-container[data-open="true"] {
  display: block;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
}

.gallery-carousel-close-icon {
  cursor: pointer;
  position: absolute;
  top: 1vw;
  right: 1vw;
}
