.advanced-button {
  position: relative;
  display: flex;
}

.advanced-button-content-container {
  border-radius: var(--size-8);
  display: grid;
  grid-template-columns: 1fr 11fr;
  width: 100%;
  align-content: center;
  position: relative;
  height: var(--size-72);
  gap: var(--size-16);
  color: var(--clr-white-87);
}
.advanced-button-icon-container {
  display: flex;
  grid-row: 1 / 3;
  grid-column: 1 / 1;
  justify-content: flex-start;
  align-items: center;
}
.advanced-button-icon {
  min-width: 24px;
  min-height: 24px;
  width: 24px;
  height: 24px;
}
.advanced-button-texts-container {
  grid-row: 1 / 3;
  grid-column: 2 / 2;
}
.advanced-button-secondary-text {
  margin-top: -2px;
}
.advanced-button-overlay {
  position: absolute;
  border-radius: var(--size-8);
  top: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0;
}
.advanced-button:hover > .advanced-button-overlay {
  opacity: 8%;
}
.advanced-button:active > .advanced-button-overlay {
  opacity: 16%;
}
