.contact-address-section-phone-number-container {
  display: flex;
  gap: var(--size-12);
}

@media (max-width: 600px) {
  .contact-address-location {
    width: 100%;
  }
}

.contact-contact-us-form-container {
  width: 90%;
}
@media (max-width: 800px) {
  .contact-contact-us-form-container {
    width: 100%;
  }
}

.contact-contact-us-your-info-form-row,
.contact-contact-us-your-message-form-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--size-24);
}

@media (max-width: 600px) {
  .contact-contact-us-your-info-form-email-phone-row {
    grid-template-columns: auto;
    grid-template-rows: auto auto;
  }
}
@media (max-width: 400px) {
  .contact-contact-us-your-info-form-name-last-name-row {
    grid-template-columns: auto;
    grid-template-rows: auto auto;
  }
}

@media (max-width: 600px) {
  .contact-contact-us-your-message-form-message-header-row {
    grid-template-columns: auto;
  }
}

.contact-contact-us-your-info-form-row-first-column,
.contact-contact-us-your-info-form-row-second-column {
  width: 100%;
}
.contact-contact-us-first-name-text-field,
.contact-contact-us-last-name-text-field,
.contact-contact-us-email-text-field,
.contact-contact-us-message-header-text-field,
.signup-form-phone-number-text-field {
  width: 100%;
  height: 64px;
}

.contact-contact-us-your-info-phone-number-container {
  display: flex;
  gap: var(--size-12);
}
.contact-contact-us-your-info-phone-number-code-dropdown {
  min-width: 114px;
}

.contact-contact-us-message-text-area {
  width: 100%;
  max-width: 100%;
  min-height: 208px;
}

.contact-contact-us-policy-text-container {
  display: flex;
  gap: var(--size-8);
}
.contact-contact-us-policy-checkbox-container {
  cursor: pointer;
}
.contact-contact-us-policy-checkbox {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
}
.contact-contact-us-buttons {
  display: flex;
  justify-content: space-between;
}

.contact-contact-us-button {
  height: var(--size-40);
}
