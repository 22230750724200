.about-us-content-contact-us {
  background-color: var(--clr-primary-900);
  width: 100%;
}

.about-us-why-mops-caravan-section-small-images-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: var(--size-24);
}
@media (max-width: 1240px) {
  .about-us-why-mops-caravan-section-small-images-wrapper {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    row-gap: var(--size-40);
  }
}
@media (max-width: 600px) {
  .about-us-why-mops-caravan-section-small-images-wrapper {
    grid-template-columns: auto;
    grid-template-rows: auto auto auto;
  }
}

@media (max-width: 1240px) {
  .about-us-why-mops-caravan-section-image-with-text:nth-child(3) {
    grid-column: 1/-1;
  }
}

.about-us-content-our-partners-images {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: var(--size-24);
}
@media (min-width: 900px) and (max-width: 1000px) {
  .about-us-content-our-partners-images {
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 720px) {
  .about-us-content-our-partners-images {
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 400px) {
  .about-us-content-our-partners-images {
    grid-template-columns: 1fr;
  }
}
