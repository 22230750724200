#language-toggle-button {
  display: inline-flex;
  gap: var(--size-8);
  cursor: pointer;
  border: none;
  align-items: center;
}
@media (max-width: 600px) {
  #language-toggle-button {
    gap: var(--size-4);
  }
}
#language-icon {
  width: 24px;
  height: 24px;
}
