.text-left-images-right {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: var(--size-24);
}
@media (max-width: 900px) {
  .text-left-images-right {
    grid-auto-flow: column;
    grid-template-rows: min-content 2fr;
    grid-template-columns: auto;
    gap: var(--size-32);
  }
}
@media (max-width: 600px) {
  .text-left-images-right {
    gap: var(--size-24);
  }
}
@media (max-width: 1180px) {
  .text-left-images-right-text {
    grid-row: 1 / 1;
    grid-column: 1 / -1;
  }
}
.text-left-images-right-images-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--size-24);
}
@media (max-width: 1180px) {
  .text-left-images-right-images-grid,
  .text-left-images-right-image-container {
    grid-row: 2 / 2;
    grid-column: 1 / -1;
  }
}
@media (max-width: 600px) {
  .text-left-images-right-images-grid {
    grid-auto-flow: column;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: auto;
  }
}

.text-left-images-right-image {
  height: 376px;
  max-height: 376px;
  width: 100%;
  object-fit: cover;
}
@media (max-width: 600px) {
  .text-left-images-right-image {
    height: auto;
    max-height: 280px;
  }
}
