.clickable-models-card {
  width: 456px;
  position: relative;
  cursor: pointer;
  background-color: var(--clr-primary-850);
  text-decoration: none;
}
@media (max-width: 600px) {
  .clickable-models-card {
    width: 100%;
  }
}

.clickable-models-card-image {
  object-fit: cover;
  /* height: 272px; */
  height: 100%;
}

.clickable-models-card-texts-container {
  display: flex;
  gap: var(--size-64);
  justify-content: space-between;
}

.clickable-models-card-model-column-beds-container {
  display: flex;
  gap: var(--size-8);
}
.clickable-models-card-model-column-seats,
.clickable-models-card-model-column-beds {
  display: flex;
  flex-wrap: nowrap;
  gap: var(--size-4);
}

.clickable-models-card-model-column-seats-icon,
.clickable-models-card-model-column-beds-icon {
  width: 24px;
  height: 24px;
}

.clickable-models-card-price-column {
  text-align: end;
}

/* Buttons */
.clickable-models-card-buttons-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--size-16);
}
@media (max-width: 900px) {
  .clickable-models-card-buttons-container {
    grid-template-columns: auto;
  }
}
@media (max-width: 600px) {
  .clickable-models-card-buttons-container {
    grid-template-columns: auto auto;
  }
}
@media (max-width: 480px) {
  .clickable-models-card-buttons-container {
    grid-template-columns: auto;
  }
}

.clickable-models-card-overlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0;
}
.clickable-models-card:hover > .clickable-models-card-overlay {
  opacity: 8%;
}
.clickable-models-card:active > .clickable-models-card-overlay {
  opacity: 16%;
}

.clickable-models-card-overlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0;
}
.clickable-models-card:hover > .clickable-models-card-overlay {
  opacity: 8%;
}
.clickable-models-card:active > .clickable-models-card-overlay {
  opacity: 16%;
}
