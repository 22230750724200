.simple-table {
  list-style: none;
}

.simple-table-titles {
  border-bottom: 1px solid var(--clr-gray-100);
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: var(--size-32);
}

.simple-table-row {
  border-bottom: 1px solid var(--clr-gray-100);
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: var(--size-32);
}
