.image-with-text {
  display: block;
  position: relative;
}
.image-with-text-image-wrapper {
  display: block;
  position: relative;
}
.image-with-text-image {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
