@font-face {
  font-family: "sf-pro-display-regular";
  src: url(../fonts/san-francisco/SF-Pro-Display-Regular.otf) format("opentype");
}
@font-face {
  font-family: "sf-pro-display-medium";
  src: url(../fonts/san-francisco/SF-Pro-Display-Medium.otf) format("opentype");
}
@font-face {
  font-family: "sf-pro-display-light";
  src: url(../fonts/san-francisco/SF-Pro-Display-Light.otf) format("opentype");
}
