.hero-navcards-wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: var(--size-24);
}

.hero-navcard {
  flex-grow: 1;
}

.home-blog-navcard {
  object-fit: cover;
}

.home-blog-navcards-wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: var(--size-24);
  align-self: end;
}

.home-blog-navcard {
  flex-grow: 1;
}

.home-contact-us-section-navcard {
  object-fit: cover;
}

.home-contact-us-section-navcards-wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: var(--size-24);
  align-self: end;
  max-width: calc(var(--container-max-width) * 2 / 3);
}

.home-contact-us-section-navcard {
  flex-grow: 1;
}

.home-why-mops-caravan-section-small-images-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: var(--size-24);
}
@media (max-width: 900px) {
  .home-why-mops-caravan-section-small-images-wrapper {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    row-gap: var(--size-40);
  }
}
@media (max-width: 600px) {
  .home-why-mops-caravan-section-small-images-wrapper {
    grid-template-columns: auto;
    grid-template-rows: auto auto auto;
  }
}

@media (max-width: 900px) {
  .home-why-mops-caravan-section-image-with-text:nth-child(3) {
    grid-column: 1/-1;
  }
}

.home-highlights-section {
  display: block;
  background-color: var(--clr-primary-800);
}
.slick-slide > div {
  margin: 0 12px;
}
.slick-list {
  margin: 0 -12px;
}
/* .slick-prev {
  left: -68px;
  top: 40%;
}
.slick-next {
  right: -40px;
  top: 40%;
}
.slick-prev:before,
.slick-next:before {
  opacity: 0.6;
} */

/* .home-highlights-carousel > .slick-prev:before {
  content: url("/public/icons/arrow-circle-left-white.svg");
}
.home-highlights-carousel > .slick-next:before {
  content: url("/public/icons/arrow-circle-right-white.svg");
} */

.home-page-highlights-carousel-dots {
  display: block;
  margin: auto;
  margin-top: var(--size-24);
  max-width: 100%;
  text-align: center;
}
@media (max-width: 900px) {
  .home-page-highlights-carousel-dots {
    margin-top: var(--size-16);
  }
}
@media (max-width: 400px) {
  .home-page-highlights-carousel-dots {
    margin-top: var(--size-8);
  }
}

.home-page-highlights-carousel-dots li {
  list-style: none;
  cursor: pointer;
  display: inline-block;
  margin-block: clamp(var(--size-10), 1vw, var(--size-16));
  margin-inline: clamp(var(--size-3), 0.3vw, var(--size-6));
}

.home-page-highlights-carousel-dots li button {
  border: none;
  background: var(--clr-white-38);
  color: transparent;
  cursor: pointer;
  display: block;
  height: var(--size-12);
  width: var(--size-12);
  border-radius: var(--size-6);
}

.home-page-highlights-carousel-dots li.slick-active button {
  width: var(--size-28);
  background: var(--clr-white-60);
}
