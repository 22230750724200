.image-left-text-right {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: var(--size-24);
}
@media (max-width: 900px) {
  .image-left-text-right {
    grid-auto-flow: column;
    grid-template-rows: min-content 2fr;
    grid-template-columns: auto;
    gap: var(--size-32);
  }
}
@media (max-width: 600px) {
  .image-left-text-right {
    gap: var(--size-24);
  }
}

.image-left-text-right-image {
  height: 376px;
  width: 100%;
  object-fit: cover;
}
@media (max-width: 600px) {
  .image-left-text-right-image {
    grid-row: 2/2;
    grid-column: 1/-1;
    height: auto;
    max-height: 280px;
  }
}
