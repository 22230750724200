.hero-section {
  display: block;
  position: relative;
  height: var(--section-height-lg);
  max-height: var(--section-max-height);
}
@media (max-width: 1140px) {
  .hero-section {
    height: var(--section-height-md);
  }
}
@media (max-width: 600px) {
  .hero-section {
    height: var(--section-height-sm);
  }
}
/* @media (max-width: 600px) {
  .hero-section {
    height: 100%;
  }
} */

.hero-section::before {
  content: "";
  position: absolute;
  z-index: -2;
  height: 100%;
  /* top: 5%; */
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  background-image: var(--hero-section-image-url);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
/* @media (max-width: 600px) {
  .hero-section::before {
    height: 100%;
  }
} */
.hero-content-wrapper-container {
  height: 100%;
}
.hero-content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.hero-logo-container {
  width: 100%;
}
@media (max-width: 600px) {
  .hero-logo-container {
    height: 80px;
  }
}
.hero-logo {
  height: 100%;
  width: 100%;
  object-fit: contain;
  /* border: 1px solid orange; */
}

/* .hero-navcards-wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: var(--size-24);
}

.hero-navcard {
  flex-grow: 1;
} */
