.rating-star-button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.rating-star {
  width: 24px;
  height: 24px;
}
