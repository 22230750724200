.partner-card {
  display: flex;
  height: 200px;
  align-content: center;
  justify-content: center;
  padding: var(--size-24);
}

.partner-card-image {
  object-fit: contain;
}
