/* appbar */
.appbar {
  position: fixed;
  z-index: 999;
  top: 0;
  height: var(--app-bar-height);
  width: 100%;
  display: flex;
  justify-content: center;
  /* opacity: 0.7;
  transition: 0.1s; */
}

/* .appbar:focus-within,
.appbar:hover {
  opacity: 1 !important;
} */

.appbar-content-wrapper {
  display: grid;
  /* position: absolute; */
  grid-template-columns: 12rem 8fr 1fr;
  /* inset: 0; */
  width: 100%;
  max-width: 2000px;
}
@media (max-width: 1080px) {
  .appbar-content-wrapper {
    grid-template-rows: 1fr 1fr;
  }
}
@media (max-width: 700px) {
  .appbar-content-wrapper {
    grid-template-columns: 2fr 8fr 2fr;
    grid-template-rows: auto;
  }
}
@media (max-width: 400px) {
  .appbar-content-wrapper {
    grid-template-columns: 4fr 4fr 4fr;
  }
}
@media (max-width: 400px) {
  .appbar-content-wrapper {
    font-size: 3.5vw;
  }
}

/* logo */
.appbar-logo-container {
  height: var(--app-bar-height);
  display: flex;
  align-items: center;
}
@media (max-width: 1080px) {
  .appbar-logo-container {
    height: calc(var(--app-bar-height) / 2 + 8px);
  }
}
@media (max-width: 700px) {
  .appbar-logo-container {
    height: var(--app-bar-height);
    grid-column: 2/2;
    display: flex;
    justify-content: center;
    padding-top: var(--size-8);
  }
}
.appbar-logo {
  height: 24.22px;
}
@media (max-width: 600px) {
  .appbar-logo {
    margin-bottom: var(--size-4);
  }
}

/* nav */
.nav {
  display: flex;
  justify-content: flex-end;
  gap: var(--size-48);
  margin: 1rem;
}
@media (max-width: 1080px) {
  .nav {
    grid-row: 2 / 2;
    grid-column: 1 / -1;
    justify-content: space-between;
    margin: 0;
  }
}
@media (max-width: 700px) {
  .nav {
    display: none;
  }
}

.nav-item {
  list-style: none;
  flex-shrink: 0;
}
.nav-item .nav-item-link {
  text-decoration: none;
}

.nav-item-link[data-dropdown="true"] {
  display: flex;
  gap: var(--size-6);
}
.models-menu-arrow {
  height: 26px;
  width: 26px;
  margin-block: auto;
  transition: padding-top 0.1s ease;
}

.nav-item[data-dropdown="true"]:hover > .nav-item-link > .models-menu-arrow {
  padding-top: 2px;
}

.nav-item-link-dropdown-container {
  display: fixed;
  position: absolute;
  top: calc(var(--app-bar-height) - var(--size-16));
  width: 200px;
}
.nav-item-link-dropdown-container[data-expanded="false"] {
  display: none;
}
@media (max-width: 1080px) {
  .nav-item-link-dropdown-container {
    top: calc(var(--app-bar-height) - var(--size-8));
  }
}

.nav-item-link-dropdown {
  list-style: none;
}

.nav-item-link-dropdown-header {
  display: block;
  text-decoration: none;
  padding-inline: var(--size-16);
  padding-block: var(--size-12);
}

.nav-item-link-dropdown-item {
  display: block;
  text-decoration: none;
  padding-inline: var(--size-16);
  padding-block: var(--size-12);
}

.nav-item-link-dropdown-item:hover {
  background-color: var(--clr-primary-500);
}

/* language toggle */

#language-toggle-wrapper {
  display: inline-flex;
  align-items: center;
}
@media (max-width: 1080px) {
  #language-toggle-wrapper {
    grid-column: 3 / 3;
  }
}

/* hamburger */
.appbar-hamburger-container {
  display: none;
}
@media (max-width: 700px) {
  .appbar-hamburger-container {
    display: flex;
    grid-column: 1/1;
    grid-row: 1/1;
  }
}
.appbar-hamburger {
  min-width: 24px;
  min-height: 24px;
}

.hamburger-menu-container[data-expanded="false"] {
  display: none;
}
.hamburger-menu-container {
  display: fixed;
  position: absolute;
  top: calc(var(--app-bar-height));
  width: 100%;
  border-bottom: 8px solid var(--clr-gray-800);
}

.hamburger-menu {
  display: flex;
  flex-direction: column;
  gap: var(--size-24);
}
.hamburger-menu-item {
  list-style: none;
  text-decoration: none;
  flex-shrink: 0;
}
.hamburger-menu-item[data-accordion="true"] {
  display: flex;
  gap: var(--size-8);
}
.hamburger-menu-item-accordion[data-expanded="false"] {
  display: none;
}
.hamburger-menu-item-accordion-header {
  display: block;
  text-decoration: none;
  padding-inline: var(--size-16);
  padding-block: var(--size-12);
}
.hamburger-menu-item-accordion-item {
  display: block;
  text-decoration: none;
  padding-inline: var(--size-16);
  padding-block: var(--size-12);
}
@media (max-width: 600px) {
  .hamburger-menu-item-accordion-item {
    padding-block: 1.5vw;
  }
}
.hamburger-menu-item-accordion-item:hover {
  background-color: var(--clr-primary-500);
}
