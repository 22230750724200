.sub-features-section-title-group {
  display: flex;
  flex-wrap: nowrap;
  gap: var(--size-40);
}
@media (max-width: 1040px) and (min-width: 980px) {
  .sub-features-section-title-group {
    flex-wrap: wrap;
  }
}
@media (max-width: 600px) {
  .sub-features-section-title-group {
    flex-wrap: wrap;
  }
}
.sub-features-section-title {
  flex-shrink: 0;
}

.sub-features-section-fancy-divider-container {
  width: 100%;
}
