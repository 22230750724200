.dropdown {
  position: relative;
  max-width: 528px;
  border-radius: var(--size-16);
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.dropdown-upper-part {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-radius: var(--size-16);
}
.dropdown-upper-part[data-primary="true"] {
  color: var(--clr-black-87);
  background-color: var(--clr-white-87);
}
.dropdown-upper-part[data-primary="false"] {
  color: var(--clr-white-87);
  /* background-color: var(--clr-black-87); */
  background-color: var(--clr-primary-1000);
}
.dropdown-upper-part[data-expanded="false"][data-primary="true"][data-border="true"] {
  border: 1px solid var(--clr-black-87);
}
.dropdown-upper-part[data-expanded="false"][data-primary="false"][data-border="true"] {
  /* border: 1px solid var(--clr-white-87); */
  border: 1px solid var(--clr-white-60);
}
.dropdown-upper-part[data-expanded="true"][data-primary="true"][data-border="true"] {
  border: none;
  border-top: 1px solid var(--clr-black-87);
  border-left: 1px solid var(--clr-black-87);
  border-right: 1px solid var(--clr-black-87);
  border-radius: var(--size-16) var(--size-16) 0 0;
}
.dropdown-upper-part[data-expanded="true"][data-primary="false"][data-border="true"] {
  border: none;
  border-top: 1px solid var(--clr-white-87);
  border-left: 1px solid var(--clr-white-87);
  border-right: 1px solid var(--clr-white-87);
  border-radius: var(--size-16) var(--size-16) 0 0;
}

@media (max-width: 600px) {
  .dropdown-upper-part {
    gap: var(--size-8);
  }
}

.dropdown-arrow-container {
  display: flex;
  align-items: center;
}

.dropdown-arrow {
  height: 28px;
  width: 28px;
  min-height: 28px;
  min-width: 28px;
  transition: padding-top 0.1s ease;
}

.dropdown-anchor {
  position: relative;
}
.dropdown-lower-part {
  border-top: 2px solid rgba(17, 17, 17, 0.2);
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  max-height: 320px;
  overflow-y: auto;
}
.dropdown-lower-part[data-primary="true"] {
  color: var(--clr-black-87);
  background-color: var(--clr-white-100);
  border-radius: 0 0 var(--size-8) var(--size-8);
}
.dropdown-lower-part[data-primary="true"][data-border="true"] {
  border-bottom: 1px solid var(--clr-black-87);
  border-left: 1px solid var(--clr-black-87);
  border-right: 1px solid var(--clr-black-87);
}
.dropdown-lower-part[data-primary="false"] {
  color: var(--clr-white-87);
  /* background-color: var(--clr-black-100); */
  background-color: var(--clr-primary-1000);
  border-radius: 0 0 var(--size-8) var(--size-8);
}
.dropdown-lower-part[data-primary="false"][data-border="true"] {
  border: none;
  border-bottom: 1px solid var(--clr-white-87);
  border-left: 1px solid var(--clr-white-87);
  border-right: 1px solid var(--clr-white-87);
}
.dropdown-lower-part[data-expanded="false"] {
  display: none;
}

.dropdown-lower-part-item {
  cursor: pointer;
}

.dropdown-lower-part-item:hover {
  background-color: var(--clr-primary-800);
}

.dropdown-lower-part-item:first-child {
  margin-top: var(--size-12);
}
