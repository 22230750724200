.price-list-card {
  /* max-width: 456px; */
  background-color: var(--clr-primary-850);
}
@media (max-width: 600px) {
  .price-list-card {
    width: 100%;
  }
}

.price-list-card-image {
  object-fit: contain;
  /* height: 272px; */
  height: 206px;
  width: 100%;
}

.price-list-card-texts-first-row-container {
  display: flex;
  gap: var(--size-64);
  justify-content: space-between;
}
.price-list-card-model-column-beds-and-people {
  display: flex;
  gap: var(--size-24);
}
.price-list-card-model-column-beds-container {
  display: flex;
  gap: var(--size-8);
}
.price-list-card-model-column-people-container {
  display: flex;
  gap: var(--size-8);
}

.price-list-card-model-column-seats-icon,
.price-list-card-model-column-person-icon {
  width: 24px;
  height: 24px;
}

.price-list-card-price-column {
  text-align: end;
}

/* Buttons */
.price-list-card-buttons-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--size-16);
}
@media (max-width: 900px) {
  .price-list-card-buttons-container {
    grid-template-columns: auto;
  }
}
@media (max-width: 600px) {
  .price-list-card-buttons-container {
    grid-template-columns: auto auto;
  }
}
@media (max-width: 480px) {
  .price-list-card-buttons-container {
    grid-template-columns: auto;
  }
}
