.page-title-section {
  height: var(--page-title-height);
  background-image: var(--page-title-image-url);
}

.page-title-wrapper {
  position: relative;
  height: 100%;
}

.page-title {
  position: absolute;
  bottom: var(--size-12);
}
