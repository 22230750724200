body {
  font-size: var(--fs-body);
  font-family: var(--ff-body);
  color: var(--clr-white-87);
}

html {
  scroll-padding-top: calc(var(--app-bar-height) + 2rem);
  overflow-x: hidden !important;
}

/* scrollbar */
/* width */
::-webkit-scrollbar {
  width: var(--size-8);
}
/* Track */
::-webkit-scrollbar-track {
  background: var(--clr-gray-900);
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--clr-gray-100);
  height: var(--size-64);
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--clr-gray-200);
}
