.blog-card {
}
.blog-card-content-wrapper {
  display: grid;
  grid-template-rows: min-content auto;
}

.blog-card-overlay-anchor {
  position: relative;
}
.blog-card-overlay-anchor:hover > .blog-card-overlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 8%;
}
.blog-card-overlay-anchor:active > .blog-card-overlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 16%;
}

.blog-card-image-container {
}

.blog-card-image {
  width: 100%;
  object-fit: cover;
}
