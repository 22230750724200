.content-list {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  padding-top: 0;
}

.content-list-any-title {
  display: block;
  border-left: 1px solid var(--clr-white-48);
  text-decoration: none;
  color: var(--clr-white-87);
  opacity: 0.8;
  padding-left: var(--size-16);
  height: var(--size-40);
}
.content-list-any-title[data-active="true"] {
  color: var(--clr-primary-400);
  border-left: 2px solid var(--clr-primary-500);
}

.content-list-sub-title {
  padding-left: var(--size-40);
}
