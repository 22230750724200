.models-dropdown-container {
  display: none;
}

.models-cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: var(--size-24);
}
@media (max-width: 900px) {
  .models-cards {
    grid-template-columns: 1fr 1fr;
    gap: var(--size-20);
  }
}
@media (max-width: 600px) {
  .models-cards {
    grid-template-columns: auto;
    gap: var(--size-16);
  }
}

.models-carousel-close-icon {
  cursor: pointer;
  position: absolute;
  top: 2vw;
  right: 2vw;
}
