.button {
  position: relative;
}
.button-content-container {
  border-radius: var(--size-8);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: var(--size-16);
}

.button-content-container[data-primary="true"] {
  color: var(--clr-black-87);
  background-color: var(--clr-primary-400);
}
.button-content-container[data-primary="true"][data-transparent="true"] {
  color: var(--clr-black-87);
  background-color: transparent;
  border: 1px solid var(--clr-black-87);
}

.button-content-container[data-primary="false"] {
  color: var(--clr-white-87);
  /* background-color: var(--clr-black-87); */
  /* border: 1px solid var(--clr-white-87); */
  background-color: var(--clr-primary-600);
}
.button-content-container[data-primary="false"][data-transparent="true"] {
  color: var(--clr-white-87);
  background-color: transparent;
  border: 1px solid var(--clr-white-87);
}

.button-left-icon-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.button-left-icon {
  min-width: 24px;
  min-height: 24px;
}

.button-right-icon-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.button-right-icon {
  min-width: 24px;
  min-height: 24px;
}

.button-overlay {
  position: absolute;
  border-radius: var(--size-8);
  top: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0;
}
.button:hover > .button-overlay {
  opacity: 8%;
}
.button:active > .button-overlay {
  opacity: 16%;
}
