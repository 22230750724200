.text-field-container {
  position: relative;
  width: 100%;
}

.text-field-placeholder-text {
  position: absolute;
  left: 24px;
  top: var(--size-20);
  transition: top 0.1s ease-in-out;
}
.text-field-placeholder-text[data-focused="true"] {
  position: absolute;
  font-size: 14px;
  left: 24px;
  top: var(--size-10);
}
.text-field {
  border-radius: var(--size-16);
  padding-top: 14px;
}
.text-field[data-primary="true"] {
  color: var(--clr-black-87);
  background-color: var(--clr-white-87);
  border: 1px solid var(--clr-black-87);
}
.text-field[data-primary="false"] {
  color: var(--clr-white-87);
  /* background-color: var(--clr-black-87); */
  /* border: 1px solid var(--clr-white-87); */
  background-color: var(--clr-primary-1000);
  border: 1px solid var(--clr-white-60);
}

.text-field-placeholder-text[data-primary="true"] {
  color: var(--clr-black-60);
}
.text-field-placeholder-text[data-primary="false"] {
  /* color: var(--clr-white-87); */
  color: var(--clr-white-60);
}
