.footer-section {
  display: block;
}

.footer-section-content-wrapper {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: min-content min-content;
  /* column-gap: var(--size-144); */
  column-gap: clamp(var(--size-96), 7vw, var(--size-160));
  row-gap: var(--size-96);
}
@media (max-width: 900px) {
  .footer-section-content-wrapper {
    grid-template-columns: auto auto;
    grid-template-rows: min-content min-content min-content;
    row-gap: var(--size-64);
    column-gap: var(--size-96);
  }
}
@media (max-width: 680px) {
  .footer-section-content-wrapper {
    grid-template-columns: auto;
    grid-template-rows: min-content min-content min-content min-content;
    row-gap: var(--size-64);
  }
}

.footer-section-logo-and-address-container {
  grid-column: 1/1;
  grid-row: 1/1;
}

.footer-logo {
  width: 265.28px;
  height: 30.47px;
  min-width: 265.28px;
  min-height: 30.47px;
}

.footer-section-nav-container {
  justify-self: start;
  grid-column: 2/2;
  grid-row: 1/1;
  display: grid;
  grid-template-columns: auto auto;
  gap: var(--size-96);
}
@media (max-width: 1080px) {
  .footer-section-nav-container {
    justify-self: start;
    grid-template-columns: auto;
    gap: var(--size-16);
  }
}
@media (max-width: 900px) {
  .footer-section-nav-container {
    grid-column: 1/1;
    grid-row: 2/2;
    grid-template-columns: auto auto;
    gap: var(--size-64);
  }
}
@media (max-width: 400px) {
  .footer-section-nav-container {
    gap: var(--size-48);
  }
}

.footer-section-nav-item,
.footer-section-social-item {
  text-decoration: none;
  color: var(--clr-white-60);
}

.footer-section-nav-first-column,
.footer-section-nav-second-column {
  display: flex;
  flex-direction: column;
  gap: var(--size-16);
}
@media (max-width: 900px) {
  .footer-section-nav-first-column,
  .footer-section-nav-second-column {
    gap: var(--size-8);
  }
}

.footer-section-social-container {
  justify-self: start;
  grid-column: 3/3;
  grid-row: 1/1;
  display: flex;
  flex-direction: column;
  gap: var(--size-12);
}
@media (max-width: 900px) {
  .footer-section-social-container {
    grid-column: 2/2;
    grid-row: 1/1;
  }
}
@media (max-width: 680px) {
  .footer-section-social-container {
    grid-column: 1/1;
    grid-row: 3/3;
  }
}
.footer-section-social-item {
  display: flex;
  gap: var(--size-8);
}
.footer-section-social-icon {
  width: var(--size-24);
  height: var(--size-24);
}

.footer-section-copyright {
  grid-column: 1/1;
  grid-row: 2/2;
}
@media (max-width: 900px) {
  .footer-section-copyright {
    grid-column: 1/1;
    grid-row: 3/3;
  }
}
@media (max-width: 680px) {
  .footer-section-copyright {
    grid-column: 1/1;
    grid-row: 4/4;
  }
}
