.images-top-texts-bottom-3-item {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: var(--size-24);
}
@media (max-width: 1080px) {
  .images-top-texts-bottom-3-item {
    grid-auto-flow: column;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: auto;
    gap: var(--size-32);
  }
}
@media (max-width: 900px) {
  .images-top-texts-bottom-3-item[data-only-image="true"] {
    gap: var(--size-40);
  }
}
@media (max-width: 600px) {
  .images-top-texts-bottom-3-item[data-only-image="true"] {
    gap: var(--size-24);
  }
}

@media (max-width: 600px) {
  .images-top-texts-bottom-3-item[data-only-image="false"] {
    gap: var(--size-48);
  }
}

.images-top-texts-bottom-2-item {
  display: grid;
  grid-template-columns: 3fr 3fr;
  gap: var(--size-24);
}
@media (max-width: 1080px) {
  .images-top-texts-bottom-2-item {
    grid-auto-flow: column;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: auto;
    gap: var(--size-32);
  }
}
@media (max-width: 600px) {
  .images-top-texts-bottom-2-item {
    gap: var(--size-40);
  }
}

.images-top-texts-bottom-image {
  height: 276px;
  width: 100%;
  object-fit: cover;
}

@media (min-width: 640px) and (max-width: 1080px) {
  .images-top-texts-bottom-texts-and-button {
    grid-row: 1 / 1;
    grid-column: 1 / 1;
  }
}

@media (max-width: 600px) {
  .images-top-texts-bottom-texts-and-button {
    grid-row: 2 / 2;
    grid-column: 1 / -1;
    width: 100%;
  }
}

@media (max-width: 1080px) {
  .images-top-texts-bottom-item-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--size-32);
  }
}
@media (max-width: 600px) {
  .images-top-texts-bottom-item-grid {
    grid-auto-flow: column;
    grid-template-rows: 1fr min-content;
    grid-template-columns: auto;
    gap: var(--size-24);
  }
}
