.fab {
  cursor: pointer;
  position: relative;
  border-radius: var(--size-32);
  display: flex;
  align-items: center;
  justify-content: center;
}

.fab-icon {
  min-width: 24px;
  min-height: 24px;
}

.fab-overlay {
  border-radius: var(--size-32);
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0;
}
.fab:hover > .fab-overlay {
  opacity: 8%;
}
.fab:active > .fab-overlay {
  opacity: 16%;
}
