.testimonial-section {
  display: block;
  position: relative;
  inset: 0;
  /* min-height: 100vh; */
}

.testimonial-section::before {
  content: "";
  position: absolute;
  z-index: -2;
  height: 100%;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  background-image: var(--testimonial-section-image-url);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.slick-slide > div {
  margin: 0 12px;
}
.slick-list {
  margin: 0 -12px;
}
.slick-prev {
  left: -68px;
}
.slick-next {
  right: -40px;
}
.slick-prev:before,
.slick-next:before {
  opacity: 0.6;
}

.reviews-carousel > .slick-prev:before {
  content: url("/public/icons/arrow-circle-left.svg");
}
.reviews-carousel > .slick-next:before {
  content: url("/public/icons/arrow-circle-right.svg");
}

.partners-carousel > .slick-prev:before {
  content: url("/public/icons/arrow-circle-left-white.svg");
}
.partners-carousel > .slick-next:before {
  content: url("/public/icons/arrow-circle-right.svg");
}

.testimonial-carousel-dots,
.partners-carousel-dots {
  display: block;
  margin: auto;
  margin-top: var(--size-24);
  padding: 0;
  border-radius: 40px;
  max-width: 100%;
  text-align: center;
}
@media (max-width: 900px) {
  .testimonial-carousel-dots,
  .partners-carousel-dots {
    margin-top: var(--size-16);
  }
}
@media (max-width: 400px) {
  .testimonial-carousel-dots,
  .partners-carousel-dots {
    margin-top: var(--size-8);
  }
}

.testimonial-carousel-dots li,
.partners-carousel-dots li {
  list-style: none;
  cursor: pointer;
  display: inline-block;
  margin-block: clamp(var(--size-10), 1vw, var(--size-16));
  margin-inline: clamp(var(--size-3), 0.3vw, var(--size-6));
}

.testimonial-carousel-dots li button,
.partners-carousel-dots li button {
  border: none;
  color: transparent;
  cursor: pointer;
  display: block;
  height: var(--size-12);
  width: var(--size-12);
  border-radius: var(--size-6);
}
.testimonial-carousel-dots li button {
  background: var(--clr-gray-400);
}
.partners-carousel-dots li button {
  background: var(--clr-white-38);
}
.testimonial-carousel-dots li.slick-active button,
.partners-carousel-dots li.slick-active button {
  width: var(--size-28);
}

.testimonial-carousel-dots li.slick-active button {
  background: var(--clr-primary-1000);
}
.partners-carousel-dots li.slick-active button {
  background: var(--clr-white-60);
}
