.model {
  background-color: var(--clr-gray-900);
}
.model-hero-section {
  display: block;
  position: relative;
  inset: 0;
  /* min-height: 100vh; */
  height: var(--section-height-lg);
  max-height: var(--section-max-height);
  z-index: 1;
}
@media (max-width: 1140px) {
  .model-hero-section {
    height: var(--section-height-md);
  }
}
@media (max-width: 600px) {
  .model-hero-section {
    height: var(--section-height-sm);
  }
}
.model-hero-section::before {
  content: "";
  position: absolute;
  z-index: -2;
  height: 100%;
  width: 100%;
  background-image: var(--model-hero-section-image-url);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
/* @media (max-width: 585px) {
  .hero-section::before {
    -webkit-transform: scale(1.45);
    -moz-transform: scale(1.45);
    -o-transform: scale(1.45);
    transform: scale(1.45);
  }
} */

.model-highlights-section {
  display: block;
  background-color: var(--clr-primary-800);
}
.slick-slide > div {
  margin: 0 12px;
}
.slick-list {
  margin: 0 -12px;
}
.slick-prev {
  left: -68px;
  top: 40%;
}
.slick-next {
  right: -40px;
  top: 40%;
}
.slick-prev:before,
.slick-next:before {
  opacity: 0.6;
}

.model-highlights-carousel > .slick-prev:before {
  content: url("/public/icons/arrow-circle-left-white.svg");
}
.model-highlights-carousel > .slick-next:before {
  content: url("/public/icons/arrow-circle-right-white.svg");
}
.model-teaser-video-section-video {
  height: clamp(420px, 32vw, 540px);
  width: 100%;
  border: 0;
}

.model-highlights-carousel-dots {
  display: block;
  margin: auto;
  margin-top: var(--size-24);
  max-width: 100%;
  text-align: center;
}
@media (max-width: 900px) {
  .model-highlights-carousel-dots {
    margin-top: var(--size-16);
  }
}
@media (max-width: 400px) {
  .model-highlights-carousel-dots {
    margin-top: var(--size-8);
  }
}

.model-highlights-carousel-dots li {
  list-style: none;
  cursor: pointer;
  display: inline-block;
  margin-block: clamp(var(--size-10), 1vw, var(--size-16));
  margin-inline: clamp(var(--size-3), 0.3vw, var(--size-6));
}

.model-highlights-carousel-dots li button {
  border: none;
  background: var(--clr-white-38);
  color: transparent;
  cursor: pointer;
  display: block;
  height: var(--size-12);
  width: var(--size-12);
  border-radius: var(--size-6);
}

.model-highlights-carousel-dots li.slick-active button {
  width: var(--size-28);
  background: var(--clr-white-60);
}
